// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  //safe-area
  --ion-safe-area-bottom: 0;
}

html {
  font-size: 16px;
}

.ios {
  font: -apple-system-body;
}

.md {
  text-size-adjust: auto;
}

.plt-mobile {
  ion-app {
    user-select: text !important;
  }
}

ion-menu{
  margin-top: env(safe-area-inset-top);
}

.forPC {
  display: none;
}

.bold {
  font-weight: 700;
}

@media (min-width: 576px) {
  .forPC {
    display: block;
  }
}


// margin, padding
.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.px10 {
  padding-right: 10px;
  padding-left: 10px;
}

.px12 {
  padding-right: 12px;
  padding-left: 12px;
}

.py20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0 !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.login-margin { //ログインページ用
  width: 100%;
  height: 55%;
}

.login-id-margin { //ログインページ用
  width: 100%;
}

.login-flex { //ログインページ用
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-notes {
  color: var(--ourcone-black-40) !important;
  font-size: var(--body-font-size-small) !important;
  white-space: normal !important;
}

.splash-grid {
  height: 100%;
}

.splash-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}