:root {
    // TEXT

    /* display */
    --display-font-size-large: 4rem;
    --display-font-size-medium: 3rem;
    --display-font-size-small: 2.25rem;
    --display-font-weight: 700;
    --display-line-height: 1.125;
    --display-letter-spacing: 0;

    /* headline */
    --headline-font-size-large: 2rem;
    --headline-font-size-medium: 1.75rem;
    --headline-font-size-small: 1.5rem;
    --headline-font-weight: 700;
    --headline-line-height: 1.5;
    --headline-letter-spacing: 0;

    /* title */
    --title-font-size-large: 1.125rem;
    --title-font-size-medium: 1rem;
    --title-font-size-small: 0.875rem;
    --title-font-weight: 700;
    --title-line-height: 1.5;
    --title-letter-spacing: 0;

    /* body */
    --body-font-size-large: 1rem;
    --body-font-size-medium: 0.875rem;
    --body-font-size-small: 0.75rem;
    --body-letter-spacing-large: 0.5;
    --body-letter-spacing-medium: 0.2;
    --body-letter-spacing-small: 0.15;
    --body-line-height: 1.5;


    // COLOR

    /* OurCone Main Color */
    --ourcone-color-dark: #21C0B4;
    --ourcone-color: #21DEB4;
    --ourcone-color-20: #4DE5C3;
    --ourcone-color-40: #7AEBD2;
    --ourcone-color-60: #A6F2E1;
    --ourcone-color-80: #D3F8F0;
    --ourcone-color-90: #E9FCF8;
    --ourcone-color-95: #F4FDFB;

    /* OurCone Black */
    --ourcone-black: #414141;
    --ourcone-black-20: #676767;
    --ourcone-black-40: #8D8D8D;
    --ourcone-black-60: #B3B3B3;
    --ourcone-black-80: #D9D9D9;
    --ourcone-black-85: #E3E3E3;
    --ourcone-black-90: #ECECEC;
    --ourcone-black-95: #F6F6F6;
    --ourcone-black-97_5: #FAFAFA;

    /* OurCone Blue */
    --ourcone-blue-dark: #2193F0;
    --ourcone-blue: #23ACF5;
    --ourcone-blue-20: #4FBDF7;
    --ourcone-blue-40: #7BCDF9;
    --ourcone-blue-60: #A7DEFB;
    --ourcone-blue-80: #D3EEFD;
    --ourcone-blue-90: #E9F7FE;
    --ourcone-blue-95: #F4FBFF;

    /* OutCone Purple */
    --ourcone-purple: #9687F5;
    --ourcone-purple-20: #AB9FF7;
    --ourcone-purple-40: #C0B7F9;
    --ourcone-purple-60: #D5CFFB;
    --ourcone-purple-80: #EAE7FD;
    --ourcone-purple-90: #F5F3FE;
    --ourcone-purple-95: #FAF9FF;

    /* OurCone Error */
    --ourcone-error: #F46273;
    --border-error: #D47278;
    --background-error: #FEF7F8;
}

// text
.title-large { // title-large
    font-size: var(--title-font-size-large);
}

.title-medium { // title-medium
    font-size: var(--title-font-size-medium);
}

.title-small { // title-small
    font-size: var(--title-font-size-small);
}

.title-large, .title-medium, .title-small {
    color: var(--ourcone-black);
    font-weight: var(--title-font-weight);
    line-height: var(--title-line-height);
    letter-spacing: var(--title-letter-spacing);
}

.headline-large { // headline-large
    font-size: var(--headline-font-size-large);
}

.headline-medium { // headline-medium
    font-size: var(--headline-font-size-medium);
}

.headline-small { // headline-small
    font-size: var(--headline-font-size-small);
}

.headline-large, .headline-medium, .headline-small {
    font-weight: var(--headline-font-weight);
    line-height: var(--headline-line-height);
    letter-spacing: var(--headline-letter-spacing);
}

.body-large { // body-large
    font-size: var(--body-font-size-large);
    letter-spacing: var(--body-letter-spacing-large);
}

.body-medium { // body-medium
    font-size: var(--body-font-size-medium);
    letter-spacing: var(--body-letter-spacing-medium);
}

.body-small { // body-small
    font-size: var(--body-font-size-small);
    letter-spacing: var(--body-letter-spacing-small);
}

.body-large, .body-medium, .body-small {
    line-height: var(--body-line-height);
}

.display-large { // display-large
    font-size: var(--display-font-size-large);
}

.display-medium { // display-medium
    font-size: var(--display-font-size-medium);
}

.display-small { // display-small
    font-size: var(--display-font-size-small);
}

.display-large, .display-medium, .display-small {
    font-weight: var(--display-font-weight);
    line-height: var(--display-line-height);
    letter-spacing: var(--display-letter-spacing);
}

// color
.oc-black { // ourcone-black
    color: var(--ourcone-black);
    --color: var(--ourcone-black);
}

.oc-black-20 { // ourcone-black-20
    color: var(--ourcone-black-20);
    --color: var(--ourcone-black-20);
}

.oc-black-40 { // ourcone-black-40
    color: var(--ourcone-black-40);
    --color: var(--ourcone-black-40);
}

.oc-blue-dark { // ourcone-blue-dark
    color: var(--ourcone-blue-dark);
    --color: var(--ourcone-blue-dark);
}

.bg-oc-black-90 { // ourcone-black-90
    background-color: var(--ourcone-black-90);
}



// Component
ion-header { // header 共通
    ion-toolbar {
        --color: var(--ourcone-black);
        --padding-top: 7px;
        --padding-bottom: 7px;

        ion-button {
            ion-icon {
                font-size: 32px !important;
            }
        }

        ion-title {
            font-size: 16px;
        }
    }

    ion-button, ion-menu-button {
      height: auto;
      --padding-end: 16px;
      --padding-start: 16px;
      --padding-top: 6px;
      --padding-bottom: 6px;
      --border-radius: 0;
      --color: var(--ourcone-black);
      --background: white;
      --background-activated: white;
      --background-hover: white;
      --background-focused: white;
    }

    ion-menu-button {
        margin: 4px 2px;
    }

    .member-icon {
        ion-icon {
            color: var(--ourcone-color-dark);
            font-size: 24px !important;
        }

        span {
            color: var(--ourcone-color-dark);
            font-size: 12px;
        }
    }

    .menu-icon {
        --padding-top: 2px;
        --padding-bottom: 2px;

        ion-icon {
            font-size: 40px;
        }
    }

    .team-icon {
        font-size: 24px !important;
    }
}

ion-item-divider { // heading-1
    --background: white;
    --color: var(--ourcone-black);
    --inner-padding-top: 8px;
    --inner-padding-bottom: 6px;
    font-size: var(--title-font-size-medium);
    font-weight: 700;
}

ion-item-divider.blue { // heading-1 blue
    border-bottom: 4px solid var(--ourcone-blue);
}

ion-item-divider.purple { // heading-1 purple
    border-bottom: 4px solid var(--ourcone-purple);
}


ion-item { // input-1
    font-size: var(--body-font-size-medium);

    ion-grid {
        font-size: var(--body-font-size-large);
    }

    ion-label {
        font-weight: 400;
        font-size: var(--body-font-size-medium) !important;
    }

    ion-col.flex {
      display: flex;
      ion-label {
        align-self: center;
      }
    }

    ion-label.overflow-wrap {
      white-space: normal !important;
    }

    ion-label.overflow-visible {
      overflow: visible !important;
    }

    ion-label.padding-left {
      padding-left: 8px;
    }

    ion-input, ion-select {
        font-size: var(--body-font-size-large) !important;
    }
}

ion-item.no-padding { // ion-item padding 0
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
}

ion-item.heading_2 { // heading-2
    margin-top: 10px;
    --min-height: 30px;
    border-bottom: 4px solid var(--ourcone-blue);

    ion-label {
        margin: 0;
        margin-top: 10px;
        font-weight: 700;
        border: none;
    }

    ion-button {
        font-size: var(--body-font-size-medium);
        --border-radius: 12px;
        height: 1.875rem;
        margin-bottom: 5px;
        margin-right: 5px;
    }

    .left_btn {
        --background: white;
        border: 1px solid var(--ourcone-black-85);
        border-radius: 12px;
        --color: var(--ourcone-black);
    }
}

ion-item-group.border-top ion-item:first-child {
    border-top: 1px solid #c8c7cc;
}

ion-item-group.group-border-top:first-child {
    border-top: 1px solid #c8c7cc;
}

ion-item.list-item-2 { // list-item-2
    --padding-bottom: 6px;
    --padding-top: 6px;

    ion-label {
        font-size: var(--title-font-size-small) !important;
    }
    ion-label.name {
        font-size: var(--title-font-size-large) !important;
    }
}

ion-item.list-item-3 { // list-item3
    --padding-bottom: 10px;
    --padding-top: 10px;
    --inner-padding-end: 20px;
    color: var(--ourcone-black);

    ion-label {
        font-size: var(--body-font-size-large) !important;
    }

    ion-row {
        ion-label {
            font-size: var(--body-font-size-small) !important;
        }
    }

    .visibility-icon {
        margin-left: 2px;
    }
}

ion-item.list-item-3.item-gray {
    --background: var(--ourcone-black-95);
}

ion-button { // button-1
    height: 3rem;
    font-weight: 700;
    --padding-start: 20px;
    --padding-end: 20px;
    --background: var(--ourcone-color-dark);
    --border-radius: 12px;
    --background-activated: var(--ourcone-color);
    --background-hover: var(--ourcone-color);
    --background-focused: var(--ourcone-color-60);
    font-size: var(--body-font-size-medium);
}

ion-button.inline-block { // button-1 inline
    min-width: 100px;
    --padding-end: 12px;
    --padding-start: 12px;
    --padding-top: 16px;
    --padding-bottom: 16px;
}

ion-button.secondary { // button-2
    --background: white;
    --color: var(--ourcone-black-20);
    border: 1px solid var(--ourcone-black-85);
    border-radius: 12px;
    --background-activated: var(--ourcone-black-95);
    --background-focused: var(--ourcone-black-95);
    --background-hover: var(--ourcone-black-95);
}

ion-button.half-block-width {
    width: 50%;
}

ion-button.secondary.button-disabled {
    --background: var(--ourcone-black-85);
}

ion-item.link_1 { // link_01, link-area_01
    margin: 8px 16px;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 15px;

    --border-color: #DBDBDB;
    --border-style: solid;
    --border-width: 1px;
    --border-radius: 30px;

    --color: var(--ourcone-black);

    ion-grid {
        padding: 4px 0;
        font-size: 0.875rem;

        ion-col {
            display: flex;
            align-items: center;
        }

        ion-col.col-icon {
            justify-content: center;
            color: var(--ourcone-blue);

            ion-icon {
                padding: 5px;
                background-color: #D3EEFD80;
                border-radius: 50%;
            }
        }
    }
}

ion-item.link_1:hover {
    background-color: var(--ourcone-black-97_5);
}

ion-button.link_2 { // link_02, link-area_02
    display: flex;
    --background: var(--ourcone-color-90);
    --border-color: #5BBDB4;
    --border-radius: 12px;
    --border-width: 1px;
    --border-style: solid;
    --color: var(--ourcone-black-20);
    font-weight: 400;

    --padding-start: 12px;
    --padding-end: 12px;
}

ion-fab-button { // button-3
    --background: var(--ourcone-color-dark);
    --background-activated: var(--ourcone-color);
    --background-focused: var(--ourcone-color);
    --background-hover: var(--ourcone-color);
}

ion-fab.top-page {
    bottom: 50px;
    right: 25px;

    ion-icon {
        font-size: 30px;
    }
}

ion-footer {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    background-color: white;

    .footer-btn {
        height: 50px;
        margin: 4px 12px;
    }
}

ion-checkbox {
    --size: 1.5rem;
    --background-checked: var(--ourcone-color-dark);
    --checkmark-color: white;
    --checkmark-width: 2px;
    --border-color-checked: var(--ourcone-color-dark);
    margin-right: 4px;
}

.v2_badge {
    font-size: var(--body-font-size-small);
    color: var(--ourcone-black);
    flex-wrap: nowrap;

    ion-col {
        display: flex;
        justify-content: flex-end;
    }
    
    ion-icon {
      font-size: 1.125rem;
      margin-right: 5px;
    }
    margin-right: 7px;
}

.v2_badge.wrap {
  flex-wrap: wrap;
}

ion-searchbar { // search-1
    --background: white;
    --border-radius: 12px;
    border-radius: 12px;
    border: 1px solid var(--ourcone-black-80);
    padding: 5px 10px !important;
    height: 44px;
    max-height: 44px;

    input{
        font-size: var(--title-font-size-medium) !important;
        background-color: white !important;
        color: var(--ourcone-black) !important;
    }

    margin-top: 30px;
    margin-bottom: 30px;

    --placeholder-color: #B3B3B3 !important;
    --placeholder-opacity: 1 !important;
}

.no-max-height {
    max-height: none;
}

ion-grid.search-2 { // search-2
    --ion-grid-padding: 10px;

    ion-row {
        border: 1px solid var(--ourcone-black-80);
        border-radius: 12px;
        margin: 0 10px;
    }

    ion-col.first {
        border-right: 1px solid var(--ourcone-black-80);
    }

    ion-col {
        padding: 0;

        ion-searchbar {
            border: none;
            margin: 0;
            padding: 0 10px !important;
            height: 100%;
        }

        ion-select {
            color: var(--ourcone-black);
            font-size: var(--body-font-size-medium);
            font-weight: 700;
            --placeholder-opacity: 1;
        }
    }
}

ion-icon {
    font-size: 1.5rem;
}

.error_msg {
    color: var(--ourcone-error);
    font-size: var(--body-font-size-medium);
    margin-left: 20px;
    margin-top: 6px;
    display: inline-block;

    ion-icon {
        margin: auto 5px auto 0;
        font-size: var(--body-font-size-medium);
    }
}

ion-item.item-1-text { // item-1 text
    --padding-bottom: 16px;
    --padding-top: 16px;

    ion-label {
        font-size: var(--body-font-size-large) !important;
    }

    ion-label.bold {
        font-size: var(--body-font-size-medium) !important;
    }

    ion-row.content {
      margin-bottom: 24px;
    }
}

.select-2 { // select-2
    --padding-end: 10px;
    color: var(--ourcone-black);

    ion-label.select-2-label {
        --color: var(--ourcone-black-60) !important;
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 5px !important;
    }
}

.select-label { // select-2-label
    margin-left: 20px;
    margin-top: 16px;
    font-size: 0.875rem !important;
    font-weight: 700;
    color: var(--ourcone-black) !important;
}

ion-toggle { // toggle-1
    --background-checked: var(--ourcone-color);
}

.toggle-1 { // toggle-1 label
    font-size: 1rem !important;
    color: var(--ourcone-black) !important;
}

ion-textarea { // textarea-1
    font-size: var(--body-font-size-large) !important;
}

.textarea-label { // textarea-1-label
  font-size: var(--body-font-size-medium) !important;
  font-weight: 700;
}

ion-textarea.textarea-2 { // textarea-2
    --color: var(--ourcone-black);
    border: 1px solid var(--ourcone-black-80);
    border-radius: 4px;
    --border-radius: 4px;
}

ion-textarea, ion-input, ion-searchbar { // placeholder-color
    --placeholder-color: var(--ourcone-black-60);
}

ion-item-group.qa-sender { // qa-sender, qa-receiver
    background-color: var(--ourcone-black-97_5);
    border: 1px solid var(--ourcone-black-85);
    border-radius: 8px;
    margin: 16px;
    font-weight: 700;

    ion-label {
        margin: 16px;
    }

    ion-item.top-menu {
        color: var(--ourcone-black);
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-end: 0;
        --background: var(--ourcone-black-97_5);
        border-radius: 12px;
    }

    ion-label.limit_date {
        --color: var(--ourcone-error);
        font-size: 0.875rem;
        white-space: normal !important;
    }

    ion-item.qa_item {
        --background: var(--ourcone-black-97_5) !important;
        font-weight: 400;
        font-size: 0.875rem;
        --padding-start: 0;
        color: var(--ourcone-black);
    }

    ion-list {
        margin-top: 10px;
        background-color: var(--ourcone-black-97_5) !important;

        ion-item {
            margin-left: 12px;
            margin-right: 12px;
            margin-bottom: 11px;

            --border-radius: 23px;
            --border-width: 1px;
            --padding-start: 16px;
            --color: var(--ourcone-black-40);

            ion-checkbox {
                margin-right: 8px;
            }

            ion-label {
                margin: 0;
                font-size: 14px;
                font-weight: 700;
            }
        }

        ion-item.item-checkbox-checked {
            --background: var(--ourcone-color-95);
            --border-color: var(--ourcone-color-dark);

            ion-label {
                --color: var(--ourcone-color-dark);
            }
        }
    }

    .sender-line {
        border-top: 2px solid var(--ourcone-black-85);
        margin: 5px 15px;
        margin-bottom: 10px;
    }
}

ion-grid.file { // file-1, file-2
    margin: 12px 20px;
    border: 1px solid var(--ourcone-black-85);
    border-radius: 8px;

    ion-label {
        font-size: 14px;
        --color: var(--ourcone-black);
    }
    ion-label.file-size {
        margin-top: 8px;
        --color: var(--ourcone-black-20);
    }

    .vertical-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.file-2-title { // file-2
    --color: var(--ourcone-black) !important;
    font-size: 1.125rem !important;
    font-weight: 700;
    margin: 0;
}

.file-2-info { // file-2
    --color: var(--ourcone-black-20) !important;
    font-size: 0.875rem !important;
    white-space: normal !important;
}

ion-item.list-item-1 { // list-item-1
    --inner-padding-bottom: 10px;
    --inner-padding-top: 10px;
    --padding-start: 12px;
    --padding-end: 0;
    --border-color: var(--ourcone-black-80);
    color: var(--ourcone-black);

    ion-label {
        font-size: var(--title-font-size-small) !important;
    }
    ion-label.name {
        font-size: var(--title-font-size-large) !important;
    }

    ion-grid {
        width: 100%;
    }

    .me-10 {
        margin-right: 10px;
    }

    ion-col {
        display: flex;
        align-items: center;

        ion-row {
            margin-left: auto;

            ion-label {
                font-size: 12px;
                --color: var(--ourcone-black-20);
                line-height: 24px;
            }
        }
    }

    ion-col.detail-icon {
      justify-content: flex-end;
      ion-icon {
        align-items: center;
      }
      ion-label {
        overflow: visible !important;
      }
    }
}

ion-item.list-item-5 { // list-item-5
    --padding-bottom: 6px;
    --padding-top: 6px;
    --padding-start: 12px;
    --padding-end: 0;
    color: var(--ourcone-black);

    ion-grid {
        width: 100%;
    }

    ion-label {
        font-size: var(--body-font-size-small) !important;
    }

    ion-col.timestamp-col {
        --ion-grid-column-padding: 0;
    }

    ion-label.timestamp {
        display: inline-block;
        --color: var(--ourcone-black-20);
        letter-spacing: var(--body-letter-spacing-small);
    }

    ion-label.group-name {
        font-weight: var(--title-font-weight);
        font-size: var(--title-font-size-large) !important;
    }

    ion-label.comment {
        font-size: var(--body-font-size-medium) !important;
    }

    ion-col.icon {
        display: inline-block;
        margin: auto 0;
    }

    ion-col.not_read_icon {
        margin: auto auto;
        padding: 0;
        text-align: -webkit-center;
    }

    .not_read {
        display: inline-block;
        margin-bottom: 3px;
        border-radius: 50%;
        text-align: center;
        background-color: var(--ourcone-error);
        width:8px;
        height: 8px;
    }

    .date_format {
        overflow: visible !important;
    }
}

.direct-message { // direct-message
  ion-text, ion-textarea {
    -webkit-user-select: auto;
    -khtml-user-select: auto;
    -ms-user-select: auto;
    -moz-user-select: auto;
    user-select: auto;
  }

  .chat_msg {
    margin: 0;
    ion-grid,ion-row {
        width: 100%;
    }
    ion-text {
      max-width: 1200px;
      text-align: left;
    }
  }

  ion-chip.date {
    height: auto;
    padding: 5px 12px;
    .hydrated {
      font-size: 0.75rem !important;
    }
    color: var(--ourcone-black-20);
    background-color: var(--ourcone-black-95);
  }

  ion-col.no-padding {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;
    padding: 0;
  }

  .chat-div-user {
    align-items: end;
    margin-right: 0;
    margin-left: auto;
    color: white;
  }

  .chat-div-target-user {
    align-items: start;
    margin-right: auto;
    margin-left: 0;
    color: var(--ourcone-black);
  }

  .chat-text-user {
    margin: 0;
    font-size: var(--body-font-size-medium) !important;
    text-align: justify;
    position: relative;
    background: var(--ourcone-blue-dark);
    border-radius: 22px;
    border-top-right-radius: 0;
    max-width: 72vw;
  }

  .chat-text-target-user {
    margin: 0;
    font-size: var(--body-font-size-medium) !important;
    text-align: justify;
    position: relative;
    background: var(--ourcone-black-95);
    border-radius: 22px;
    border-top-left-radius: 0;
    max-width: 72vw;
  }

  .send-at-user {
    padding-top: 3px;
    font-size: var(--body-font-size-small)  !important;
    color: var(--ourcone-black-40) !important;
  }

  .send-at-target-user {
    text-align: end;
    padding-top: 3px;
    font-size: var(--body-font-size-small)  !important;
    color: var(--ourcone-black-40) !important;
  }

  .user {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .target-user {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}

ion-footer.send-footer-1 { // direct-message, mailing-detail
    padding: 0;

    ion-grid {
        padding: 23px 16px;
    }

    ion-textarea.chat-textarea {
        div {
            padding: 0 5px;
            border: 1px solid var(--ourcone-black-80);
            border-radius: 30px;
            background-color: var(--ourcone-black-97_5);
            textarea {
                padding-left: 15px !important;
                padding-top: 8px;
                padding-bottom: 5px;
            }
        }
    }

    ion-button.send-button {
        align-items: center;
        height: 40px !important;
        width: 40px !important;
        --border-radius: 50%;
        margin: 0;
        margin-left: 10px;

        position: relative;

        ion-icon {
            position: fixed;
            top: 11px;
            left: 12px;
            font-size: 16px;
        }
    }
}

ion-item-group.post-1 { //post-1
    ion-item.post-body {
        --border-color: var(--ourcone-black-85);

        .reply-item {
            padding-left: 0;
            padding-right: 0;
            padding-top: 16px;
            padding-bottom: 16px;
        }

        ion-row, ion-col {
            padding-left: 0;
            padding-right: 0;
        }

        ion-text {
            margin-top: 10px;
            margin-left: 0;
            margin-right: 0;
            overflow-wrap: anywhere;
        }

        .reply-title {
            font-size: 1rem !important;
            font-weight: 700;
            color: var(--ourcone-black) !important;
        }

        ion-text {
            font-size: 1rem;
            color: var(--ourcone-black);
            white-space: pre-wrap;
        }

        ion-text.reply-time {
            font-size: 0.875rem !important;
            color: var(--ourcone-black-40) !important;
        }
    }
}

.login-status { // login-status
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  color: var(--ourcone-blue-dark);

  .border {
    display: flex;
  }

  .border::before, .border::after {
    border-top: 2px solid;
    content: "";
    width: 2.5em;
  }

  .border-off {
    color: var(--ourcone-black-85);
  }
}

ion-item.input-2 { // input_02
  margin: 0;
  margin-bottom: 20px;
  --padding-start: 10px;
  ion-grid {
    margin: 0;
    padding-left: 0;
    padding-right: 0;

    ion-col {
        padding-right: 0;
        padding-left: 0;
    }
  }
  ion-label { // Labelあり
    margin-right: 0;

    ion-icon { // Iconあり
      margin-right: 8px;
    }
  }
  .label-text {
    font-size: var(--body-font-size-medium);
    font-weight: var(--title-font-weight);
  }

  ion-icon.no-me {
    margin-right: 0;
  }

  ion-input {
    border: 1px solid;
    border-radius: 4px;
    border-color: var(--ourcone-black-80);
    input {
      padding: 8px !important ;
    }
  }

  ion-input.invalid-input{ //　invalid時
    background-color: var(--background-error);
    opacity: 0.95;
    border-color: var(--border-error);
  }
}

.logo-2 { // logo_02
  width: 260px;
  margin: 20px auto;
}

ion-accordion-group.accordion-1 { // accordion-1
    ion-accordion {
        border: 1px solid var(--ourcone-black-80);

        ion-item.item-header {
            --padding-start: 16px;

            ion-label {
                font-size: 14px;
                color: var(--ourcone-black);
                margin: 0;
            }
        }

        div.content-list {
            padding: 0 16px;
            font-size: 14px;

            ion-label {
                color: var(--ourcone-black);
            }
        }

        ion-item.content-item {
            --padding-start: 0;
            --padding-end: 0;
            --padding-top: 0;
            --padding-bottom: 0;
            --inner-padding-end: 0;
            --min-height: 15px;

            ion-label.name {
                margin: 8px 0;
            }
        }

        ion-item.content-item:last-child {
            margin-bottom: 12px;
        }
    }
    ion-accordion.accordion-expanded, ion-accordion.accordion-expanding {
        ion-item.item-header {
            ion-label {
                font-weight: 700;
            }
        }
    }
}

.question-icon {
    color: var(--ourcone-black);
}

ion-item.qa-sender-edit-time { // qa-sender-edit-time
    --padding-start: 10px;
    --padding-top: 8px;
    --padding-bottom: 8px;
    border-top: 1px solid #c8c7cc;

    ion-grid {
        padding-left: 0;
        padding-right: 0;
    }

    .question-title {
        font-size: 0.875rem;
        font-weight: 700;
    }
    .question-body {
        font-size: 1rem !important;
        margin-top: 8px;
        color: #E36B76;
        font-weight: 700;
    }

    ion-col.question-end-icon {
        ion-icon {
            margin-top: 20px;
            color: var(--ourcone-black);
            margin-right: 0px;
            font-size: 32px;
        }
    }
}

ion-item.list-item-4 { // list-item-4
    --padding-bottom: 6px;
    --padding-top: 6px;
    color: var(--ourcone-black);

    ion-label {
        font-size: var(--title-font-size-small) !important;
    }
    ion-label.name {
        font-size: var(--title-font-size-large) !important;
    }
    ion-label.badge {
        color: var(--ourcone-black-20);
        font-size: var(--body-font-size-small) !important;
        letter-spacing: var(--body-letter-spacing-small);
    }
}

// inbox
ion-item.inbox {
    color: var(--ourcone-black);
    --inner-padding-end: 0;

    --padding-start: 0;
    --padding-end: 0;

    --border-color: var(--ourcone-black-90);

    ion-grid {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 20px;
        padding-left: 16px;
        padding-right: 16px;

        ion-row {
            font-size: 0.875rem;
            padding-top: 5px;
        }

        ion-col {
            padding-left: 0;
            padding-right: 0;
            color: var(--ourcone-black);
        }

        ion-icon {
            font-size: 1.125rem;
            margin-right: 6px;
        }

        ion-col.icons {
            ion-icon {
                margin-right: 6px;
            }
        }

        .team-icon {
            padding: 2px;
            background-color: white;
            border: 1px solid var(--ourcone-black-90);
            border-radius: 2px;
            font-size: 0.75rem;
        }

        .created-at {
            font-size: 0.75rem;
            color: var(--ourcone-black-20);
        }

        .creator {
            display: flex;
            font-size: 1.125rem;
            overflow: hidden;
        }

        .label-flex {
            flex: 1;
            align-items: center;
            overflow: hidden;
        }

        .text-wrap {
            max-width: 100%;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .team-col {
            padding-bottom: 0;
        }
    }

    ion-grid.inbox-unread {
        background-color: var(--ourcone-blue-95);

        .unread-font {
            font-weight: 700;
        }
    }

    ion-grid.inbox-read {
        background-color: var(--ourcone-black-95);
    }
}

ion-content.modal-2 { // modal-2
    .title {
        margin-top: 16px;
        margin-bottom: 10px;
        --color: var(--ourcone-blue-dark);
        font-size: 1.125rem;
        font-weight: 700;
    }

    .content {
        font-size: 1rem;
        --color: var(--ourcone-black);
    }
}


ion-menu.drawer { // Drawer, drawer-admin, drawer-general
    --min-width: 330px;

    ion-content::part(scroll) {
        display: flex !important;
        flex-direction: column !important;
    }

    ion-item {
        --padding-start: 10px;
        --padding-end: 10px;
        --inner-padding-end: 0;
    }

    ion-item.profile {
        --background: none;
        background-image: url("../assets/image/background.png");
        background-repeat: no-repeat;
        background-size: contain;

        min-height: 248px;

        font-size: 0.875rem;
        color: var(--ourcone-black-20);
        --inner-padding-end: 0;

        flex-shrink: 0;

        ion-grid {
            padding: 16px;
            margin-top: 24px;

            ion-button {
                margin-top : 20px;
                margin-bottom: 4px;
            }
        }

        ion-row.department {
            margin-top: 10px;

            ion-label {
                font-size: 0.75rem !important;
            }
        }

        ion-col {
            padding-left: 0;
            padding-right: 0;
        }

        .admin-badge {
            padding: 4px 6px;
            background-color: var(--ourcone-blue-dark);
            border-radius: 4px;
            color: white;
            font-size: 0.875rem;
        }

        .logout {
            color: var(--ourcone-error);
            font-size: 0.875rem;
            text-decoration: underline;
            font-weight: 700;
        }

        .profile-name {
            font-weight: 700;
            font-size: 1.125rem !important;
            color: var(--ourcone-black);
        }

        .email {
            margin-top: 6px;
        }
    }

    ion-item.menu-bar {
        --padding-start: 5px;
        --padding-end: 5px;

        flex-shrink: 0;
        color: var(--ourcone-black);

        ion-grid {
            padding: 0;
            margin-top: 10px;
        }

        ion-row {
            justify-content: center;
        }

        ion-col {
            border-radius: 12px 12px 0 0;

            margin-left: 3px;
            margin-right: 3px;
            padding-top: 11px;
            padding-bottom: 8px;

            font-size: 1.75rem;
        }

        ion-col.active {
            background-color: var(--ourcone-color-90);
            border-bottom: 3px solid var(--ourcone-color-dark);
        }
    }

    ion-item-group {
        padding: 12px;
        padding-top: 20px;

        flex-grow: 1;
        overflow: scroll;

        ion-accordion-group {
            ion-item {
                --padding-start: 10px;
            }
            ion-icon.ion-accordion-toggle-icon {
                font-size: 0.75rem;
                margin-right: 6px;
            }

            .header-item {
                margin-top: 0;
                margin-bottom: 0;
                --background-activated: var(--ourcone-color-90);
                --background-activated-opacity: 1;
                border-radius: 12px;
            }

            .accordion-content {
                padding-left: 30px;

                ion-icon {
                    margin-right: 0;
                }

                .team-name {
                    font-size: var(--body-font-size-large) !important;
                }
            }
        }

        ion-item {
            --padding-start: 30px;
            font-size: 1rem;
            color: var(--ourcone-black);
            --color: var(--ourcone-black);
            margin-top: 7px;
            margin-bottom: 7px;

            ion-icon {
                --color: var(--ourcone-black);
                margin-right: 10px;
            }
        }
    }

    .icon-badge {
        position: relative;
        margin-right: 10px;
    }

    .no-mr {
        margin-right: 0;
    }

    .dot {
        position: absolute;
        width: 0.575rem;
        height: 0.575rem;
        border-radius: 50%;
        background-color: var(--ourcone-error);
        top: 0;
        right: 0;
    }

    .version {
        bottom: 0;
        right: 0;
        font-size: 0.875rem;
        color: var(--ourcone-black);
        text-align: right;
    }

    @media (max-height: 700px) {
        .version {
            position: inherit;
            padding-right: 8px;
            text-align: right;
        }
    }

    .not-admin {
        margin-left: 11px !important;
        margin-right: 11px !important;
    }
}

ion-button.link-3 { // link_03
    height: 2.25rem;
    --border-radius: 12px;
    --border-color: var(--ourcone-black-85);
    --border-width: 1px;
    --border-style: solid;
    --color: var(--ourcone-black);
    --background: white;
    --padding-end: 12px;

    --background-activated: var(--ourcone-black-85);

    ion-icon {
        font-size: 0.875rem;
    }
}

ion-item.item-1-icon { // item-1 icon
    --padding-top: 16px;

    ion-icon {
      color: var(--ourcone-black-20);
      padding-right: 8px;
    }

    ion-label.bold {
        font-size: var(--body-font-size-medium) !important;
    }

    ion-col {
      padding: 0;
    }

    ion-text {
      display: block;
      margin-top: 8px;
      margin-bottom: 8px;
      margin-left: 35px;
      font-size: var(--body-font-size-large);
      color: var(--ourcone-black);
    }
}

ion-item.list-item-6 { // list-item-06
    --padding-top: 16px;
    --padding-start: 10px !important;

    ion-icon {
        color: var(--ourcone-black-20);
        padding-right: 8px;
    }

    ion-label.bold {
        font-size: var(--body-font-size-medium) !important;
    }

    ion-item-group {
        margin-top: 16px;
        width: 100%;
        border: 1px solid var(--ourcone-black-85);
        border-radius: 8px;
        background-color: var(--ourcone-black-97_5);

        ion-list {
            margin: 16px;

            .team-id {
                font-size: var(--body-font-size-large);
                --ion-item-background: var(--ourcone-black-97_5);
            }

            ion-item.team-id:first-child {
                ion-text.team-id-label{
                    margin-top: 0;
                }
            }

            ion-text {
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }
    }
}

ion-header.header-7 {
  ion-toolbar {
    padding-bottom: 10px;
    padding-top: 10px !important;
  }
}

// modal
ion-modal {
    --width: 100%;
    --height: 100%;
}

.brake {
  white-space: pre-wrap !important;
}

.direct-message-content {
  a {
      color: rgb(255, 255, 255) !important;
  }
}

.user-info {
    --padding-start: 16px !important;
    --padding-end: 16px;
    --padding-top: 32px;
    --padding-bottom: 32px;
    --background: #F6F6F6;
    color: var(--ourcone-black);

    ion-grid {
        ion-row {
            ion-col {
                display: flex;
                padding: 0;
                padding-top: 5px;
            }
        }
    }
}

.info-list {
    ion-item {
        color: var(--ourcone-black);
    }
}

.alert-wrapper { // alerts-1, alerts-2
  .alert-title {
    font-size: var(--body-font-size-large);
  }

  .alert-button {
    font-size: var(--body-font-size-large);
  }
}

.icon-purple {
    color: var(--ourcone-purple);
}

// placeholder
ion-input, ion-textarea {
    --placeholder-color: #B3B3B3 !important;
    --placeholder-opacity: 1 !important;
}